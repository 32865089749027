import type { RouteLocationNormalized, Router } from 'vue-router'
import { getAccessToken, isUserLoggedIn } from '@/api/authentication'
import { useSessionStore } from '@/store/session'

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.allowAnonymous) {
      return true
    }

    if (!(await isUserLoggedIn())) {
      return { name: 'loginError' }
    }

    const session = useSessionStore()
    if (!session.isLoaded) {
      const token = await getAccessToken()
      if (!(await session.setToken(token))) {
        return { name: 'permissionError' }
      }
    }

    return true
  })
}
