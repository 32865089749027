import type { Session } from '@/api/models/session'
import { getSession } from '@/api/session'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSessionStore = defineStore('user', () => {
  const token = ref<string | null>(null)
  const session = ref<Session>({ userId: '', userName: '', email: '' })
  const updating = ref(false)

  async function setToken(value: string | null) {
    token.value = value
    if (!updating.value) {
      updating.value = true
      session.value = await getSession()
      updating.value = false
    }
    return !!session.value.userId
  }

  return {
    isLoaded: computed(() => !!session.value.userId),
    session,
    setToken,
  }
})
