<template>
  <v-navigation-drawer
    :model-value="props.modelValue"
    @update:model-value="modelValueChanged"
  >
    <v-list lines="two">
      <v-list-item
        v-for="item in topLevelItems"
        :key="item.title"
        :prepend-icon="item.icon"
        :title="item.title"
        :to="item.to"
        link
      ></v-list-item>

      <v-list-group v-for="group in groups" :key="group.title">
        <template #activator="slot">
          <v-list-item
            v-bind="slot.props"
            :title="group.title"
            :prepend-icon="group.icon"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="item in group.items"
          :key="item.title"
          :value="item.title"
          :title="item.title"
          :to="item.to"
          link
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ modelValue: boolean | null }>(), {
  modelValue: null,
})

const emit = defineEmits(['update:modelValue'])

function modelValueChanged(newValue: boolean | null) {
  emit('update:modelValue', newValue)
}

const topLevelItems = [
  {
    title: 'Home',
    icon: '$home',
    to: { name: 'home' },
  },
  {
    title: 'Pull Requests',
    icon: '$pullRequest',
    to: { name: 'pull-requests' },
  },
  {
    title: 'Configuration',
    icon: '$configurationKey',
    to: { name: 'configuration' },
  },
  {
    title: 'Secrets',
    icon: '$secret',
    to: { name: 'secrets' },
  },
]

const groups = [
  {
    title: 'Features',
    icon: '$feature',
    items: [
      {
        title: 'Rollout',
        to: { name: 'features-rollout' },
      },
      {
        title: 'Active',
        to: { name: 'features-active' },
      },
      {
        title: 'Permanent',
        to: { name: 'features-permanent' },
      },
      {
        title: 'Deprecated',
        to: { name: 'features-deprecated' },
      },
    ],
  },
  {
    title: 'Deployments',
    icon: '$deployment',
    items: [
      {
        title: 'Overview',
        to: { name: 'deployment-overview' },
      },
      {
        title: 'Recent',
        to: { name: 'deployments' },
      },
    ],
  },
]
</script>

<style>
.v-list-item-title {
  font-size: 0.9rem;
}
</style>
