<template>
  <v-btn @click="toggleTheme">
    <template #prepend>
      <TransitionGroup type="transition">
        <template v-for="(theme, index) in themes">
          <v-icon
            v-if="themeName === index"
            :key="theme.name"
            :icon="theme.icon"
            :color="theme.color"
          />
        </template>
      </TransitionGroup>
    </template>

    Theme:
    <span class="text-capitalize">{{ themeName }}</span>
  </v-btn>
</template>

<script setup lang="ts">
import { useTheme } from '@simplifi/vuetify'

const { themeName, themes, toggleTheme } = useTheme()
</script>

<style scoped>
.v-list-item-title {
  user-select: none;
}
.v-icon {
  opacity: 1 !important;
}

.v-enter-from,
.v-leave-to {
  position: absolute;
  opacity: 0 !important;
}
</style>
