<script setup lang="ts">
import { useRouter } from 'vue-router'
import { REDIRECT_PATH_STORAGE_KEY, getAccessToken } from '@/api/authentication'

const path = localStorage.getItem(REDIRECT_PATH_STORAGE_KEY)
const router = useRouter()

if ((await getAccessToken()) && path) {
  router.replace({ path })
} else {
  router.push({ name: 'loginError' })
}
</script>
