<template>
  <v-app-bar class="justify-start pl-1 pr-1" :floating="false" color="primary">
    <v-app-bar-nav-icon @click.stop="menuClicked" />
    <img src="@/assets/logo.svg" class="logo" />
    <v-app-bar-title class="mr-5 toolbar__title">
      SIMPLIFi Development
    </v-app-bar-title>
    <v-app-bar-title> </v-app-bar-title>
    <v-spacer />
    <v-btn icon data-testid="user-menu-button">
      <v-icon icon="$account" />
      <v-menu
        activator="parent"
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <UserMenu />
      </v-menu>
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts" setup>
import UserMenu from '@/components/nav/user-menu.vue'
const props = withDefaults(defineProps<{ modelValue: boolean | null }>(), {
  modelValue: null,
})

const emit = defineEmits(['update:modelValue'])

function menuClicked() {
  emit('update:modelValue', !props.modelValue)
}
</script>

<style lang="scss">
.logo {
  max-width: 30px;
  margin-left: 1rem;
}

.toolbar__title {
  flex: 0 1 auto !important;
}
</style>
