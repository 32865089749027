<template>
  <v-card :elevation="16" :title="session.userName" :subtitle="session.email">
    <template v-slot:prepend>
      <v-avatar icon="$account" :size="85" color="surface-variant" />
    </template>
    <v-divider />
    <v-card-actions>
      <ThemeToggle />
      <v-btn prepend-icon="$logout" @click="logOut">Log Out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { logOut } from '@/api/authentication'
import ThemeToggle from '@/components/general/theme-toggle.vue'
import { useSessionStore } from '@/store/session'
import { storeToRefs } from 'pinia'

const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
</script>
