<template>
  <NavBar v-model="drawer" />
  <SideBar v-model="drawer" />
  <v-main class="d-flex flex-grow-1 overflow-y-auto flex-column">
    <div class="pa-8">
      <router-view v-slot="{ Component }">
        <Transition :mode="'out-in'" :appear="true">
          <Suspense>
            <div>
              <component :is="Component" />
            </div>
            <template #fallback>
              <v-progress-circular />
            </template>
          </Suspense>
        </Transition>
      </router-view>
    </div>
  </v-main>
</template>

<script setup lang="ts">
import NavBar from '@/components/nav/nav-bar.vue'
import SideBar from '@/components/nav/side-bar.vue'
import { ref } from 'vue'

const drawer = ref(null)
</script>
